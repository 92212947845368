<template>
  <!-- 补充用量表 -->
  <div class="page">
    <div class="leftDiv">
      <div class="leftTopDiv title">条形码<span class="pageBtn finger btnSure fr" @click="inputLineCode">查询</span></div>
      <div class="leftTopBtm areaFlex1">
        <i-input type="textarea" placeholder="请输入带入库条形码，多个产品换行显示" v-model="lineCode"></i-input>
      </div>
    </div>
    <div class="rightDiv">
      <div class="rightTopDiv title">用量清单<span class="pageBtn finger btnReset fr" @click="goBack">返回</span></div>
      <selfTable :productList="listColumns1" :productData="meterList1" :isLoad="isLoad"> </selfTable>
      <div class="rightTopDiv marginTop14 title">待补充明细清单<span class="longTextBtn finger btnReset fr marginLeft20" @click="input">模版导入</span><span class="longTextBtn finger btnReset fr" @click="output">模版下载</span></div>
      <Table :columns="listColumns2" :data="meterList2" border class="waitAdd">
        <template slot-scope="{ row, index }" slot="tiaoma"
          ><!-- :disabled="row.turnable" -->
          <Input placeholder="请输入" size="small" v-model="row.tiaoma" @input="changeTiaoma(row.tiaoma, index)" />
        </template>
        <template slot-scope="{ row, index }" slot="pihao"
          ><!-- :disabled="row.turnable" -->
          <Input placeholder="请输入" size="small" v-model="row.pihao" @on-change="changePihao(row.pihao, index)" />
        </template>
        <template slot-scope="{ row, index }" slot="shuliang"
          ><!-- :disabled="row.turnable" -->
          <Input placeholder="请输入" size="small" v-model="row.shuliang" @on-change="changeShuliang(row.shuliang, index)" type="number" />
        </template>
        <template slot-scope="{ row, index }" slot="time">
          <DatePicker type="date" placeholder="请选择" v-model="row.time" size="small" placement="left-start" @on-change="changeDate(row.time, index)"></DatePicker>
        </template>
      </Table>
      <div style="margin: 25px 0">
        <span class="pageBtn finger btnSure fr" @click="sure">确认</span>
      </div>
    </div>
    <!-- <tips-component showModal="delete" @cancleBtn="deleteModel=false" @sureBrn="deleteAddItem(deleteIndex)" v-if="deleteModel"></tips-component> -->
    <!-- 删除模态框 -->
    <Modal v-model="deleteModel" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否删除记录？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="deleteAddItem(deleteIndex)">确定</span>
          <span class="pageBtn finger btnCancle" @click="deleteModel = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import selfTable from '@/components/table'
export default {
  components: {
    selfTable
  },
  name: 'addMeter',
  data() {
    return {
      usage_id: 0,
      isLoad: false,
      /* 补充列表删除序列 */
      deleteIndex: 0,
      /* 显示提示框 */
      deleteModel: false,
      /* 用量清单 */
      meterList1: [
        /* {
          name: '一次性使用消化道吻合器',
          xinghao: 'RV2213',
          sku: 'RV2213',
          yongliang: '1',
          danwei: '个'
        },
        {
          name: '一次性使用消化道吻合器',
          xinghao: 'RV2213',
          sku: 'RV2213',
          yongliang: '1',
          danwei: '个'
        } */
      ],
      listColumns1: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          minWidth: 255
        },
        {
          title: '规格型号',
          key: 'xinghao',
          align: 'center',
          width: 150
        },
        {
          title: '货号/SKU',
          key: 'sku',
          align: 'center',
          width: 140
        },
        {
          title: '用量',
          key: 'yongliang',
          align: 'center',
          width: 140
        },
        {
          title: '单位',
          key: 'danwei',
          align: 'center',
          width: 140
        },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      this.getAddlist(params.row)
                    }
                  }
                },
                '添加'
              )
            ])
          }
        }
      ],
      /* 待补充明细清单 */
      meterList2: [],
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          sortable: true,
          minWidth: 220
        },
        {
          title: '规格型号',
          key: 'xinghao',
          align: 'center',
          width: 160
        },
        {
          title: '货号/SKU',
          key: 'sku',
          align: 'center',
          width: 160
        },
        {
          title: '条形码',
          slot: 'tiaoma',
          align: 'center',
          width: 160
        },
        {
          title: '批号',
          slot: 'pihao',
          align: 'center',
          width: 160
        },
        {
          title: '数量',
          slot: 'shuliang',
          align: 'center',
          width: 160
        },
        {
          title: '有效期',
          slot: 'time',
          align: 'center',
          width: 160
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      this.deleteModel = true
                      this.deleteIndex = param.index
                    }
                  }
                },
                '删除'
              )
            ])
          }
        }
      ],
      lineCode: ''
    }
  },
  methods: {
    changeSelect(e, name, index) {
      this.$set(this.meterList2[index], name, e)
    },
    /* 核对待补充用量清单并发送 */
    sure() {
      // console.log(this.meterList1)
      // console.log(this.meterList2)
      let addList1 = this.meterList1
      let flag = true
      for (const item of addList1) {
        let count = Number(item.yongliang) /* 用量清单总数 */
        let itemCount = 0 /* 补充用量清单总数 */
        let usage_receive_id = item.usage_receive_id
        let addList2 = this.meterList2.filter(value => {
          return value.usage_receive_id == usage_receive_id
        })
        for (const item2 of addList2) {
          if (!Number(item2.shuliang) || Number(item2.shuliang) <= 0) {
            this.$Message.error('请输入有效数字')
            flag = false
            return
          }
          itemCount = itemCount + Number(item2.shuliang)
          if (!item2.time) {
            this.$Message.error('有效期不能为空')
            flag = false
            return
          }
          if (!item2.pihao) {
            this.$Message.error('批号不能为空')
            flag = false
            return
          }
        }
        if (itemCount != count) {
          this.$Message.error('补充数量与用量表不一致')
          flag = false
          return
        }
      }
      if (flag) {
        let addList = this.meterList2
        let usage_id = this.usage_id
        let usage_info = []
        for (const item of addList) {
          let product_id = item.product_id
          let product_model_name = item.xinghao
          let usage_amount = item.shuliang
          let barcode = item.tiaoma
          let product_name = item.name
          let batch_number = item.pihao
          let business_end_date = item.time
          let obj = { product_id, product_model_name, usage_amount, barcode, batch_number, business_end_date, product_name }
          usage_info.push(obj)
        }
        let listData = { usage_id, usage_info }
        console.log(listData)
        this.$http.post(this.$api.meterSupplement, listData).then(res => {
          if (res.status) {
            this.$Message.success(res.message)
            this.getDetail()
            this.meterList2 = []
            this.$router.push('/meterManage')
          } else {
            this.$Message.error('补充失败')
          }
        })
      }
    },
    goBack() {
      this.$router.push('meterManage')
    },
    /* 获取用量表细节 */
    getDetail() {
      this.$http.get(this.$api.getUsageDetail, { usage_id: this.usage_id }).then(res => {
        if (res.status) {
          let detailList = []
          for (const item of res.data) {
            let product_id = item.product_id
            let usage_receive_id = item.usage_receive_id
            let name = item.product_name
            let xinghao = item.product_model_name
            let sku = item.product_item_number
            let yongliang = item.usage_amount
            let danwei = item.product_unit
            // let turnable = false
            let obj = { product_id, usage_receive_id, name, xinghao, sku, yongliang, danwei }
            detailList.push(obj)
          }
          this.meterList1 = detailList
        }
      })
    },
    /* 获取待补充用量清单 */
    getAddlist(item) {
      let obj = {}
      obj.name = item.name
      obj.xinghao = item.xinghao
      obj.sku = item.sku
      obj.tiaoma = ''
      obj.pihao = ''
      obj.shuliang = ''
      obj.time = ''
      obj.usage_receive_id = item.usage_receive_id
      obj.product_id = item.product_id
      // obj.turnable = item.turnable
      this.meterList2.push(obj)
    },
    /* 删除待补充明细清单 */
    deleteAddItem(index) {
      this.meterList2.splice(index, 1)
      this.$Message.success('删除成功')
      this.deleteModel = false
    },
    /* 改变条码 */
    changeTiaoma(item, index) {
      this.$set(this.meterList2[index], 'tiaoma', item)
    },
    /* 改变批号 */
    changePihao(item, index) {
      this.$set(this.meterList2[index], 'pihao', item)
    },
    /* 改变数量 */
    changeShuliang(item, index) {
      // this.$set(this.meterList2[index], 'shuliang', item)
      let str = item + ''
      let strIndex = str.indexOf('.')
      let symbalIndex = str.indexOf('-')
      this.$set(this.meterList2[index], 'shuliang', item)
      if (strIndex !== -1) {
        this.$Message.error('不能为小数')
        this.$set(this.meterList2[index], 'shuliang', null)
      }
      if (symbalIndex !== -1) {
        this.$Message.error('不能为负数')
        this.$set(this.meterList2[index], 'shuliang', null)
      }
      if (str.length > 8) {
        this.$Message.error('数字长度不超过8')
        this.$set(this.meterList2[index], 'shuliang', null)
      }
    },
    /* 改变日期 */
    changeDate(item, index) {
      item = item ? this.$moment(item).format('YYYY-MM-DD') : ''
      this.$set(this.meterList2[index], 'time', item)
    },
    /* 条形码数据
010694218038685417220811108685112921022
010694218038685417220804108685112321015
010694218038690817220811108690200821019
010694218038695317220809108695230021002
010694218038685417220804108685112321015
010694218038690817220811108690200821019
010694218038695317220809108695230021002
010694218038685417220804108685112321015
010694218038690817220811108690200821019
010694218038695317220809108695230021002
010694218038685417220804108685112321015
010694218038690817220811108690200821019
010694218038695317220809108695230021002 */
    /* 查询条形码并输入待补充明细用量清单 */
    inputLineCode() {
      let lineCode = this.lineCode
      if (lineCode) {
        let barcode_list = []
        barcode_list = lineCode.split('\n')
        this.$http.post(this.$api.addLineCode, { barcode_list }, false).then(res => {
          if (res.status) {
            console.log(res.data)
            for (const item of res.data) {
              let obj = {}
              obj.product_id = item.product_id
              obj.name = item.name
              obj.xinghao = item.model_name
              obj.sku = item.item_number
              obj.tiaoma = item.bar_code
              obj.pihao = item.batch_number
              obj.shuliang = '1'
              obj.time = item.valid_period
              // obj.turnable = true
              if (
                this.meterList1.find(val => {
                  obj.usage_receive_id = val.usage_receive_id
                  return val.name === obj.name && val.xinghao === obj.xinghao
                })
              ) {
                this.meterList2.push(obj)
              }
            }
          }
        })
      } else {
        this.$Message.error('请输入条形码')
      }
    },
    // 导出
    output() {
      this.$Message.error('此功能还在开发中')
    },
    // 导出
    input() {
      this.$Message.error('此功能还在开发中')
    }
  },
  created() {
    this.usage_id = this.$route.query.id
    this.getDetail()
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  overflow: hidden;
  /deep/ .areaFlex1 {
    flex: 1;
    .ivu-input-wrapper {
      height: 100% !important;
    }
    .ivu-input {
      height: 100% !important;
    }
  }
  .title {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #525b6d;
    line-height: 25px;
  }
  .leftDiv {
    width: 370px;
    border-right: 1px solid #e8eaec;
    padding: 27px 24px 26px 24px;
    display: flex;
    flex-direction: column;
    .leftTopDiv {
      margin-bottom: 21px;
      margin-top: 5px;
    }
  }
  .rightDiv {
    padding: 27px 24px 26px 24px;
    flex: 1;
    overflow-y: auto;
    .rightTopDiv {
      height: 36px;
      line-height: 36px;
      margin-bottom: 14px;
    }
    .marginTop14 {
      margin-top: 14px;
    }
    .waitAdd {
      position: static !important;
      /* !important */
    }
  }
}
</style>
